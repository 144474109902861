import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ButtonControl from './ButtonControl'

const LightboxHeader = ({ galleryTitle, images, currentIndex, onClose }) => (
  <TopHeaderBar>
    <LeftSideDescriptionContainer>
      <GalleryHeading>{galleryTitle}</GalleryHeading>
      <GallerySubheading>{images[currentIndex].alt}</GallerySubheading>
    </LeftSideDescriptionContainer>

    <RightSideContainer>
      <PageIndicator>
        {currentIndex + 1} / {images.length}
      </PageIndicator>
      <CloseButton onClick={onClose} type="button">
        &times;
      </CloseButton>
    </RightSideContainer>
  </TopHeaderBar>
)

LightboxHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  galleryTitle: PropTypes.string.isRequired,
  currentIndex: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default LightboxHeader

const GalleryHeading = styled.h2`
  margin: 0 0 5px 0;
  font-weight: normal;
`

const GallerySubheading = styled.h4`
  margin: 0;
  font-weight: normal;
  color: ${({ theme }) => theme.pageContentLinkHoverColor};
`

const PageIndicator = styled.span`
  white-space: nowrap;
  min-width: 60px;
  text-align: center;
`

const RightSideContainer = styled.div`
  width: 117px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled(ButtonControl)`
  height: 100%;
  display: grid;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.green};
  font-size: 2rem;
`

const LeftSideDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0 8px 10px;
`

const TopHeaderBar = styled.header`
  z-index: 10;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  padding: 0;
  color: #ffffff;
  > * {
    height: inherit;
  }
`
