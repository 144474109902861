import React from 'react'
import { Link } from 'gatsby'
import { Box, Link as RebassLink, Text } from 'rebass/styled-components'

import Container from '../components/container'

export const ContactUsSection = () => {
  return (
    <Box
      variant="sections.contactUs"
      sx={{
        backgroundImage: `linear-gradient(95.94deg, #49B96A 21.42%, #389454 78.68%)`,
      }}
    >
      <Container>
        <Box variant="sections.contactUs.boxContent">
          <Box
            variant="sections.contactUs.textContent"
            sx={{ gridAutoFlow: ['row', 'column'] }}
          >
            <Text as="p">
              Zaujaly nás naše produkty, služby či reference?
            </Text>

            <Text as="p">
              <strong>Budeme velmi rádi, když nás kontaktujete.</strong>
            </Text>

            <RebassLink variant="linkButton" as={Link} to="/kontakt">
              Kontaktujte nás
            </RebassLink>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
