import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'rebass/styled-components'

import Container from '../components/container'
import { ImageGallery } from '../components/Gallery'
import reliefBg from '../images/relief-bg.png'

export const ReferenceGallery = () => {
  const data = useStaticQuery(graphql`
    {
      referenceImages: allFile(
        filter: { absolutePath: { regex: "/\/reference\//" } }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
  `)
  const referenceImages = data.referenceImages.edges.map(({ node }) => node)

  return (
    <Box
      variant="sections.reference"
      sx={{
        pb: [5, 5, 6],
        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75%), url(${reliefBg})`,
        backgroundSize: '100%, 50%',
      }}
    >
      <Container>
        {referenceImages && referenceImages.length > 0 && (
          <ImageGallery images={referenceImages} />
        )}
      </Container>
    </Box>
  )
}
