export const getImages = (images) => {
  return images.map((image) => {
    return {
      src: image.childImageSharp.gatsbyImageData.images.fallback.src,
      alt: '',
      caption: '',
      width: image.childImageSharp.gatsbyImageData.width,
      height: image.childImageSharp.gatsbyImageData.height,
    }
  })
}

export const getLightboxImages = (images) => {
  return images.map((image) => ({
    src: image.childImageSharp.gatsbyImageData.images.fallback.src,
    width: image.childImageSharp.gatsbyImageData.width,
    height: image.childImageSharp.gatsbyImageData.height,
  }))
}
