import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'rebass/styled-components'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Hero } from '../components/hero'
import { ContactUsSection } from '../sections/contact-us'
import { ReferenceGallery } from '../sections/gallery'

const ReferencePage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/reference-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Ukázky naší práce" />

      <Hero image={heroBg} bgPosition="center 60%" title="Ukázky naší práce" />

      <Box sx={{ mt: -6 }}>
        <ReferenceGallery />
      </Box>

      <ContactUsSection />
    </Layout>
  )
}

export default ReferencePage
