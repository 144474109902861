import styled from 'styled-components'

export default styled.button`
  z-index: 10;
  background-color: #ffffff;
  border-style: none;
  cursor: pointer;
  padding: 2rem 1rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.blue};
`
